<template lang='pug'>
#Contacts.main-page.page-noScroll.pb-0.position-relative
    loadingOverlay(v-show='loading' message='Loading . . .' :zIndex='999')
    v-row#page-head.d-flex.align-center.justify-md-space-between(v-if='!loading')
      v-col.d-flex.align-center.px-0.pb-0.px-md-2.pb-md-4(cols='4' md='2' style="z-index:1;")
        .d-flex.align-center
          h1.my-0 {{$t('CONTACT.TITLE')}}
          v-btn.ml-3.add-deal-btn(@click='showAddContact()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
            v-icon(size='16') icon-add
          v-btn.ml-3.add-deal-btn(v-if='selectedRows.length>0' @click='showBulkEditDialog()' width='40' height='40' :ripple='false' color='success' depressed dark fab)
            v-icon(size='16') icon-edit
          v-btn.ml-3.add-deal-btn(v-if='selectedRows.length>0' @click='follow()' :title="$t('FOLLOW.CONTACT')" width='40' height='40' :ripple='false' color='success' depressed dark fab)
            v-icon(size='20') mdi-star
      v-col(cols='12' md='10' )
        v-row.justify-md-end(no-gutters='no-gutters')
          v-col(cols='auto')
            v-progress-circular(size='18' v-if='loading_content' class='mr-3' indeterminate color='primary')
            .btn-group
                v-btn(@click='onCopy(selectedRows[0])' :disabled='selectedRows.length > 1 || selectedRows.length == 0' width='80' height='38' color='white' depressed tile dark)
                    v-icon(size='16') icon-edit
                    span {{$t('GENERAL.COPY')}}
                v-btn.border-left(@click='onDelete()' :items="contactData" :disabled="selectedRows.length == 0" width='80' height='38' color='white' depressed tile dark)
                    v-icon(size='16') icon-remove
                    span {{$t('GENERAL.DELETE')}}
                v-menu(offset-y)
                    template(v-slot:activator="{ on, attrs }")
                        v-btn.border-left(@click='' v-bind="attrs" v-on="on" :disabled='selectedRows.length < 1 ' width='100' height='38' color='white' depressed tile dark)
                            v-icon.pa-1(size='20') mdi-bullhorn-outline
                            span {{$t('CONTACT.BULK')}}
                    v-list
                        v-list-item(@click='openEmailDrawer()') Email
                        v-list-item(@click='openSMSDrawer()') SMS                
                v-btn.border-left(@click='showAddStaticSeg()' :disabled='selectedRows.length < 1 ' width='160' height='38' color='white' depressed tile dark)
                    v-icon.pr-1.pt-1(size='20') mdi-chart-pie
                    span {{$t('CONTACT.SEGMENT')}}
          v-col(cols='auto').ml-4.d-flex.btn-group-with-label-contact
            label {{$t('FILTERSETTING.CS')}}
            .btn-group.ml-4
                setting_btn(:filter_list='filter_list',:tables='tables',:currentTable='currentTable',:default_filter='default_filter', tab='Contact', :is_staff='is_staff', :stage-type-list='[]' @emitView='onEmitView' @emitFilter='onEmitFilter' :customViews='customViews')
          v-col(cols='auto').ml-4.d-flex
            .btn-group
                v-text-field(v-model='searchContact' flat dense solo prepend-inner-icon='icon-search')
            importExportBtn(@emitFilter='onEmitFilter' :page='"Contact"' :selectStageType='null' :search='searchContact' :filter='default_filter.id')
    #page-inner.scroll-x.pt-md-6.pb-0.height_100vh
      //- .data-area
      v-form(v-model='valid' ref='form').data-area
        v-data-table.deals-table(
            :headers='contactHeader'
            :header-props="{sortIcon: 'mdi mdi-arrow-up'}" 
            :items='contactData'
            :items-per-page='itemPerPage'
            :page.sync='page'
            :page-count='pageCount'
            :sort-desc.sync='sortByDesc'
            :sort-by.sync="sortBy"
            multi-sort
            height='100%'
            hide-default-footer='hide-default-footer' 
            show-select 
            item-key='id' 
            v-if='!loading_content && !loading'
            v-model="selectedRows" 
            :loading='loading_content || loading_edit' 
            loading-text="Loading..."
            v-resize-table="headerData"
            fixed-header
        )
          //- v-data-table.deals-table(
          //-     :headers='contactHeader'
          //-     :header-props="{sortIcon: 'mdi mdi-arrow-up'}" 
          //-     :items='contactData'
          //-     :items-per-page='itemPerPage'
          //-     :page.sync='page'
          //-     :page-count='pageCount'
          //-     :sort-desc.sync='sortByDesc'
          //-     :sort-by.sync="sortBy"
          //-     multi-sort
          //-     height='100%'
          //-     hide-default-footer='hide-default-footer' 
          //-     show-select
          //-     item-key='id'
          //-     v-if='!loading'
          //-     v-model="selectedRows"
          //-     :loading='isLoading'
          //-     loading-text="Loading..."
          //-     v-columns-resizable
          //-     fixed-header
          //- )
          //- 聯絡人姓名 full_name
          template(v-slot:item.full_name ='{ item }')
            div(class=' d-flex justify-left' :title='item.full_name' ) 
                followIcon(:follow='item.follow')
                v-list-item-group(class='inline-edit-cell justify-start')
                    v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="40")
                        v-icon(v-if="item.image == null") mdi-account 
                        img(v-else :src='item.image')
                    a(v-if='!!item.full_name' :href="'Contacts/' + item.id" class='ellipsis') {{ item.full_name }}
                inlineEditDialog(field_type='full_name' editProp='full_name' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
          
          //- 公司名稱 org_name
          template(v-slot:item.org_name='{ item }')
            div(class=' d-flex justify-center' :title='item.org_name' )
                .inline-edit-cell
                    a(v-if="item.organization_set != ''" :href="'Account/'+ item.organization_set[0].id" class='ml-8 ellipsis' :title='item.organization_set[0].name') {{ item.organization_set[0].name }}
                inlineEditDialog(field_type='select_org' editProp='organization_set' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
          
          //- 行動電話 mobile_phone
          template(v-slot:item.mobile_phone='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.mobile_phone' )
                .inline-edit-cell 
                    a(class='ml-4 ellipsis' v-if='!!item.mobile_phone' :title='item.mobile_phone' :href='onCall(item.mobile_phone)' ) {{ item.mobile_phone }}
                inlineEditDialog(field_type='mobile_phone' editProp='mobile_phone' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
          
          //- 連絡電話 office_phone
          template(v-slot:item.office_phone='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.office_phone' )
                .inline-edit-cell 
                    a(class='ml-4 ellipsis' v-if='!!item.office_phone' :title='item.office_phone' :href='onCall(item.office_phone)' ) {{ item.office_phone }}
                inlineEditDialog(field_type='text_len50' editProp='office_phone' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
          
          //- 電子郵件 email
          template(v-slot:item.email='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.email' )
                .inline-edit-cell 
                    a(class='ml-4 ellipsis' :title='item.email' :href='onMail(item.email)' ) {{ item.email }}
                inlineEditDialog(field_type='email' editProp='email' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

          //- 進行商機總額 total_amount
          template(v-slot:item.total_amount='{ item }')
            .ellipsis.d-flex.justify-end
              span {{ item.total_amount_adj }}
          
          //- 擁有者 owner_name
          template(v-slot:item.owner_name='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.owner_name' ) 
              .inline-edit-cell 
                v-list-item-avatar.d-inline-block.ma-0.mx-4(color="aliceBlue", size="40")
                      v-icon(v-if="item.owner == null") mdi-account 
                      v-icon(v-else-if="item.owner.usersetting.photo == null") mdi-account
                      img(v-else :src='item.owner.usersetting.photo')
                a(v-if='!!item.owner' :href='onMail(item.owner.email)' class='ellipsis') {{ item.owner.last_name+' '+item.owner.first_name }}
              inlineEditDialog(field_type='select' editProp='owner' :selectList='ownerList' item-text="fullName" :editItem='item' @emitInlineEdit='onEmitInlineEdit')
              
          //- 客製化欄位 custom_field
          template(v-for='e, index in cf_header' v-slot:[`item.${e.value}`]='{ item }')
              div(v-if='item.custom_field != null' class='d-flex justify-center')
                  .inline-edit-cell
                      v-icon(v-if='e.type == "bol" && item.custom_field[`${e.prop}`] === true' class='ml-8 ellipsis' size="18" color="green" ) fas fa-check 
                      span(v-else-if='e.type == "bol" && item.custom_field[`${e.prop}`] === false')
                      span(v-else-if='e.type == "opt" || e.type == "tst"' class='ml-8 ellipsis' :title='getItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field[`${e.prop}`])') {{ getItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field[`${e.prop}`]) }}
                      span(v-else-if='e.type == "mlt"' class='ml-8 ellipsis' :title='getCustomfieldOptItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field[`${e.prop}`])') {{ getCustomfieldOptItemNameFromList(CFdef[`${e.prop}`].optList, item.custom_field[`${e.prop}`]) }}
                      span(v-else class='ml-8 ellipsis' :title='item.custom_field[`${e.prop}`]') {{ item.custom_field[`${e.prop}`] }}
                  inlineEditDialog(v-if='e.type != "opt" && e.type != "mlt" && e.type != "tst"' :field_type='"custom_field_"+e.type' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' @emitInlineEdit='onEmitInlineEdit_cf')
                  inlineEditDialog(v-if='e.type == "opt"' field_type='custom_field_opt' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].optList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf')
                  inlineEditDialog(v-if='e.type == "mlt"' field_type='custom_field_mlt' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].optList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf')
                  inlineEditDialog(v-if='e.type == "tst"' field_type='custom_field_tst' :editProp='e.prop' :editItem='item' :cf_mandatory='CFdef[`${e.prop}`].mandatory' :selectList='CFdef[`${e.prop}`].treeList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit_cf' :treeSelectList='CFdef[`${e.prop}`].optList')
          //- 傳真 fax 
          template(v-slot:item.fax='{ item }')
              div(class='d-flex justify-center ' :title='item.fax')
                  .inline-edit-cell
                      span(class='ml-8 ellipsis') {{item.fax}}
                  inlineEditDialog(field_type='text_len20' editProp='fax' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
          //- 生日 birthdate 
          template(v-slot:item.birthdate='{ item }')
              div(class='d-flex justify-center ' :title='item.birthdate')
                  .inline-edit-cell
                      span(class='ml-8 ellipsis') {{item.birthdate}}
                  inlineEditDialog(field_type='date' editProp='birthdate' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
          
          //- 部門 department 
          template(v-slot:item.department='{ item }')
              div(class='d-flex justify-center ' :title='item.department')
                  .inline-edit-cell
                      span(class='ml-8 ellipsis') {{item.department}}
                  inlineEditDialog(field_type='text_len50' editProp='department' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
          
          //- 建立時間
          template(v-slot:item.created_on='{ item }')
           .t-black.ellipsis {{ new Date(item.created_on).toLocaleString() }}
          
          //- 更新時間
          template(v-slot:item.updated_on='{ item }')
           .t-black.ellipsis {{ new Date(item.updated_on).toLocaleString() }}
          
          //- 級別
          template(v-slot:item.level='{ item }')
            div(class=' d-flex justify-left position-relative' :title='getItemNameFromList(levelList, item.level)' )
              .inline-edit-cell
                span(class='ml-8 ellipsis' v-if='item.level != null') {{ getItemNameFromList(levelList, item.level) }}
              inlineEditDialog(field_type='select' editProp='level' :editItem='item' :selectList='levelList' item-text="name" item-value="key" @emitInlineEdit='onEmitInlineEdit')

          //- 職稱
          template(v-slot:item.title='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.title' )
              .inline-edit-cell 
                span(class='ml-8 ellipsis') {{ item.title }}
              inlineEditDialog(field_type='text_len50' editProp='title' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

          //- facebook
          template(v-slot:item.facebook='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.facebook' )
              .inline-edit-cell
                  a(class='ml-8 ellipsis' :href='item.facebook') {{ item.facebook }}
              inlineEditDialog(field_type='url' editProp='facebook' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

          //- linkedin
          template(v-slot:item.linkedin='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.linkedin' )
              .inline-edit-cell
                  a(class='ml-8 ellipsis' :href='item.linkedin') {{ item.linkedin }}
              inlineEditDialog(field_type='url' editProp='linkedin' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

          //- instagram_id
          template(v-slot:item.instagram_id='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.instagram_id' )
              .inline-edit-cell
                  a(class='ml-8 ellipsis' v-if='item.instagram_id != null' :href='"https://www.instagram.com/"+item.instagram_id+"/"') {{ item.instagram_id }}
              inlineEditDialog(field_type='text_len50' editProp='instagram_id' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
          
          //- Line Line
          template(v-slot:item.line_id='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.line_id' )
              .inline-edit-cell
                  a(class='ml-8 ellipsis' v-if='item.line_id != null' :href='"line://ti/p/" + item.line_id') {{ item.line_id }}
              inlineEditDialog(field_type='text_len50' editProp='line_id' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

          //- 地址
          template(v-slot:item.billing_address='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.billing_address' )
              .inline-edit-cell
                  a(class='ml-8 ellipsis' :href='onGoogleMap(item.billing_address)') {{ item.billing_address }}
              inlineEditDialog(field_type='text' editProp='billing_address' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
          //- 城市 city 
          template(v-slot:item.city='{ item }')
              div(class='d-flex justify-center ' :title='item.city')
                  .inline-edit-cell
                      span(class='ml-8 ellipsis') {{item.city}}
                  inlineEditDialog(field_type='text_len50' editProp='city' :editItem='item' @emitInlineEdit='onEmitInlineEdit')
          
          //- 國家 country 
          template(v-slot:item.country='{ item }')
              div(class='d-flex justify-center ' :title='item.country')
                  .inline-edit-cell
                      span(class='ml-8 ellipsis') {{item.country}}
                  inlineEditDialog(field_type='text_len20' editProp='country' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

          //- 省分 province 
          template(v-slot:item.province='{ item }')
              div(class='d-flex justify-center ' :title='item.province')
                  .inline-edit-cell
                      span(class='ml-8 ellipsis') {{item.province}}
                  inlineEditDialog(field_type='text_len50' editProp='province' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

          //- 郵遞區號 zip-code
          template(v-slot:item.zip_code='{ item }')
              div(class='d-flex justify-center ' :title='item.zip_code')
                  .inline-edit-cell
                      span(class='ml-8 ellipsis') {{item.zip_code}}
                  inlineEditDialog(field_type='text_len10' editProp='zip_code' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

          //- 相關活動
          template(v-slot:item.primary_campaign_id='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.primary_campaign_name' )
              .inline-edit-cell
                  a(class='ml-8 ellipsis' :href="'Campaign/'+item.primary_campaign_id") {{ getItemNameFromListById(campaignList, item.primary_campaign_id) }}
              inlineEditDialog(field_type='autocomplete' editProp='primary_campaign_id' :selectList='campaignList' item-text="name" item-value="id" :editItem='item' @emitInlineEdit='onEmitInlineEdit')

          //- 描述 description
          template(v-slot:item.description='{ item }')
            div(class=' d-flex justify-left position-relative' :title='item.description' )
              .inline-edit-cell
                span(class='ml-8 ellipsis' :title='item.description') {{ item.description }}
              inlineEditDialog(field_type='textarea' editProp='description' :editItem='item' @emitInlineEdit='onEmitInlineEdit')

          //- 建立者
          template(v-slot:item.created_by='{ item }')
            div(class='d-flex w-100 align-center justify-start ml-8' :title='item.created_by.last_name+" "+item.created_by.first_name')
                v-list-item-avatar.ma-0.mr-4.d-inline-block(color='aliceBlue' size='40' v-if="item.created_by != null")
                    v-icon(v-if="item.created_by.usersetting.photo == null") mdi-account
                    img(v-else :src='item.created_by.usersetting.photo')
                a(v-if="item.created_by != null" :href='onMail(item.created_by.email)' class='ellipsis') {{ item.created_by.last_name+' '+item.created_by.first_name }}
          
          //- 更新者
          template(v-slot:item.updated_by='{ item }')
            div(class='d-flex w-100 align-center justify-start ml-8' :title='item.updated_by.last_name+" "+item.updated_by.first_name')
                v-list-item-avatar.ma-0.mr-4.d-inline-block(color='aliceBlue' size='40' v-if="item.updated_by != null")
                    v-icon(v-if="item.updated_by.usersetting.photo == null") mdi-account
                    img(v-else :src='item.updated_by.usersetting.photo')
                a(v-if="item.updated_by != null" :href='onMail(item.updated_by.email)' class='ellipsis') {{ item.updated_by.last_name+' '+item.updated_by.first_name }}
          
          //- 執行
          template(v-slot:item.action='{ item }')
            .ellipsis
                v-btn(icon='icon' @click='showEditContact(item)')
                    v-icon(size='18') icon-edit
                v-btn(icon='icon' @click='onDelete(item.id)')
                    v-icon(size='18') icon-remove
        label.text-right.mr-4(v-if='!loading && !loading_content') {{(page-1)*itemPerPage+1}} － {{currentData}} of {{pageDataCount}} 
        v-pagination.py-1.mb-3(v-if='!loading && !loading_content' v-model='page' :length='pageCount' circle :total-visible="10")
        
    v-navigation-drawer(v-model="emailDrawer" absolute bottom temporary right width="800")
        v-list(nav dense).pa-4
            v-list-item-group(v-model="group" active-class="deep-purple--text text--accent-4")         
                v-list-item-title.d-flex.align-center.justify-center
                    h3 {{$t('CONTACT.EMAIL_PUSH')}}
                emailEditor(v-if='sentEmail' :owner-data='ownerData' :objectType='objectType' :userEmail='userEmail' :contactListDetail='contactListDetail' :sentEmailList='sentEmailList' @emitSendEmailDialog='onEmitSendEmailDialog')
    v-navigation-drawer(v-model="SMSDrawer" absolute bottom temporary right width="600")
        v-list(nav dense).pa-4
            v-list-item-group(v-model="group" active-class="deep-purple--text text--accent-4")          
                v-list-item-title.d-flex.align-center.justify-center 
                    h3 {{$t('CONTACT.SMS_PUSH')}}         
                emailEditor(v-if='sentSMS' :owner-data='ownerData' :objectType='objectType' :userEmail='userEmail' :contactListDetail='contactListDetail' :sentEmailList='sentEmailList' @emitSendEmailDialog='onEmitSendEmailDialog')
    v-navigation-drawer.v-navigation-drawer-bulk-edit(v-model='bulkEditDialog'  v-if='bulkEditDialog' absolute bottom temporary right width="400" zIndex='99999')
        bulk-edit-dialog(@emitBulkEditDialog='onEmitBulkEditDialog' :editItems='selectedRows')
    v-dialog(v-model='addContactDialog' max-width='500' @keydown.esc='onEmitAddContactDialog(false)' v-if='addContactDialog')
        add-contact(@emitAddContactDialog='onEmitAddContactDialog' :add-contact='addContactData')
    v-dialog(v-model='editContactDialog' max-width='500' @keydown.esc='onEmitEditContactDialog(false)' v-if='editContactDialog')
        edit-contact(@emitEditContactDialog='onEmitEditContactDialog' :contact-data='editContactData')
    v-dialog(v-model='messageDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitMessageDialog(false)' @keydown.enter='onEmitMessageDialog(true)')
        message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
    v-dialog(v-model='successDialog' width='360' content-class='statusDialog' @keydown.enter='onEmitSuccessDialog(true)')
        success-dialog(@emitSuccessDialog='onEmitSuccessDialog')
    v-dialog(v-model='errorDialog' width='360' content-class='statusDialog' @keydown.esc='onEmitErrorDialog(false)' @keydown.enter='onEmitErrorDialog(true)')
        error-dialog(@emitErrorDialog='onEmitErrorDialog' :errorMessage='errorMessage')
    v-dialog(v-model='addStaticSegDialog' max-width='500' v-if='addStaticSegDialog')
        add-static-seg(@emitAddStaticSeg='onEmitAddStaticSeg' )
    v-dialog(v-model='addSegDialog' max-width='500' v-if='addSegDialog')
        add-seg(@emitAddSeg='onEmitAddSeg' :addSegment='addSegment' :fromContact='true')
    v-dialog(v-model='redirectDialog' width='360' content-class='statusDialog')
        redirect-dialog(@emitRedirectDialog='onEmitRedirectDialog' :message='message' :copyDataType='"contact"' :copyDataId='copyDataId')
    v-dialog(v-model='editTableDialog' width='360' content-class='statusDialog' v-if='editTableDialog')
        edit-table-dialog(@emitEditTableDialog='onEmitEditTableDialog' @emitEditTable='onEmitEditTable' :custom-views='customViews' :edit-data='currentTable' :edit-mode='editMode')
</template>

<script>
// dialog
import addContact from "@/components/Dialog/Contact/addContact";
import editContact from "@/components/Dialog/Contact/editContact";
import addStaticSeg from "@/components/Dialog/Contact/addStaticSegment";
import messageDialog from "@/components/Dialog/messageDialog";
import successDialog from "@/components/Dialog/successDialog";
import errorDialog from "@/components/Dialog/errorDialog";
import redirectDialog from '@/components/Dialog/redirectDialog';
import editTableDialog from "@/components/Dialog/Setting/editTableDialog";
import addSeg from '@/components/Dialog/Segment/addSegment';
import inlineEditDialog from '@/components/Common/inlineEditDialog';
import bulkEditDialog from "@/components/Dialog/BulkEdit/contactBulkEditDialog";
// drawer
import emailEditor from '@/components/Common/emailEditorContacts';
// service
import ContactDataService from "../services/ContactDataService";
import CustomViewDataService from '@/services/CustomViewDataService';
import CustomFilterDataService from "@/services/CustomFilterDataService"; 
import CustomFieldDataService from "@/services/CustomFieldDataService";
import O365Service from "@/services/O365Service"; 
import OrganizationDataService from "@/services/OrganizationDataService";
import CampaignDataService from "@/services/CampaignDataService";
import BulkEditDataService from "@/services/BulkEditDataService";
import FollowDataService from '../services/FollowDataService';
import TreeOptionsDataService from '@/services/TreeOptionsDataService';

// list
import contactsFieldList from "@/array/the_contactsFieldList";
import levelList from "@/array/the_levelList";
// other
import loadingOverlay from "@/components/Common/loadingOverlay";
import i18n from '/common/plugins/vue-i18n.js';
import importExportBtn from "@/components/Common/importExportBtn";
import setting_btn from "@/components/Common/setting_btn";
import followIcon from "@/components/Common/followIcon";

export default {
  components: {
    // dialog
    redirectDialog,
    editTableDialog,
    messageDialog,
    successDialog,
    errorDialog,
    addSeg,
    addContact,
    editContact,
    addStaticSeg,
    bulkEditDialog,
    // drawer
    emailEditor,
    //other
    loadingOverlay,
    importExportBtn,
    setting_btn,
    inlineEditDialog,
    followIcon,
  },
  data() {
    return {
        select_datasheet:true,
        select_filter:false,
        user: JSON.parse(window.localStorage.getItem('user')),
        is_staff: JSON.parse(window.localStorage.getItem('is_staff')),
        loading: true,
        // table setting     
        awaitingSearch: false,   
        sortBy:[],
        sortByForSearch:[],
        sortByDesc:[],
        pageDataCount:null,
        page: 1,
        pageCount: 0,
        itemPerPage: 50,
        currentData:0,
        selectedRows: [],
        loading_content: true,
        //list
        contactsFieldList: contactsFieldList,
        levelList: levelList,
        // Contact Table Data
        contactListDetail:null,
        searchContact: "",
        contactHeader: [],
        contactData: [],
        addContactData: {},
        editContactData: {},
        deleteContactIds: '',
        addSegment:{},
        cf_header: [],
        // Dialog
        importDialog:false,
        exportDialog:false,
        redirectDialog: false,
        copyDataId:null,
        addSegDialog: false,
        messageDialog: false,
        message: "",
        successDialog: false,
        successMessage: "",
        errorDialog: false,
        errorMessage: "",
        addContactDialog: false,
        editContactDialog: false,
        addStaticSegDialog: false,
        originData: {},
        // navigation drawer
        emailDrawer: false,
        SMSDrawer: false,
        group: null,
        contactEmail:'',
        sentEmailList:[],
        userEmail: '',
        ownerData: "",
        sentEmail:false,
        sentSMS:false,
        objectType:'',
        // table setting
        editTableDialog: false,
        tables: [],
        currentTable: {},
        customViews: [],
        editMode: 'EDIT',
        fcmToken: window.localStorage.getItem('fcm_token'),     
        default_filter:null,   
        filter_list : [],
        first_run:false,
        system_default_filter: null,
        customField:null,
        bulkEditDialog:false,
        CFdef: {},
        valid: true,
        campaignList: null,
        ownerList: null,
        loading_edit: false,
        headerData:{
            id:"",
            name:"",
            value:{},
            page:""
        },
    };
  },
  async created() {
    let filter_id = this.getDefaultFilter("Contact",null)
    await (this.getFilterList())
    await (this.default_filter = this.getPageDefaultFilter(filter_id,this.filter_list, this.system_default_filter));
    await this.getListData();
    await this.getCustomView();
    await this.getCFdef();
    await this.getCampaginList();
    await this.getOwnerList();
    // await this.getWebPushPreference();
    this.getLoginUserEmail();
    await( this.first_run = true )
  },
  deactivated(){
    alert("deactivated")
  },
  beforeDestroy(){
    console.log("beforeDestroy")
  },
  methods: {
    follow(){
      let add_list=[];
      let delete_list=[];
      for(let i = 0; i < this.selectedRows.length; i++)
        {
          if(!this.selectedRows[i].follow){
            add_list.push(this.selectedRows[i].id);
          }else{
            delete_list.push(this.selectedRows[i].id)
          }
        }
      let data = {
        "add_list":add_list,
        "delete_list":delete_list,
        "class_name":"Contact"
      }
      FollowDataService.bulkCreateDeleteFollow(data)
      .then((response)=>{
        for(let i in response.data.add_list){
          let contact = this.contactData.filter(el => el.id == response.data.add_list[i]);
          contact[0].follow = true;
        }
        for(let i in response.data.delete_list){
          let contact = this.contactData.filter(el => el.id == response.data.delete_list[i]);
          contact[0].follow = false;
        }
        this.selectedRows = [];
      })
    },
    onEmitInlineEdit(item, prop, value){
        this.loading_edit = true;
        item[prop] = value;
        if(prop=="select_org"){
            if(value.length > 0){
                item["org_name"] = value[0].name;
            }else{
                item["org_name"] = "";
            }
        }
        let editData = JSON.parse(JSON.stringify(item));
        editData.created_by = editData.created_by.id;
        editData.updated_by = editData.updated_by.id;
        editData.organizations = [];

        if(prop == 'owner'){
          editData = {
            "owner": value,
            "last_name": item.last_name,
            "id": item.id
          }
        }
        ContactDataService.edit(editData)
        .then((response)=>{
            if(prop == 'owner'){
              item.owner.usersetting.photo = response.data.owner.usersetting.photo;
              item.owner_name = value.full_name;
              item.owner_full_name = value.full_name;
              item.owner_full_name_zh = value.full_name;
              if(value.first_name !=""){
                  item.owner_full_name_en = value.first_name + " " +value.last_name;
              }else{
                  item.owner_full_name_en = value.last_name;
              }
            }
            else if(prop == 'organization_set'){
                
                if(item.organization_set.length != 0){
                    if(item.organization_set[0].company_number != "0"){
                        // taiwan company data set
                        item.organization_set[0].id = ((response.data).organization_set.find(e => e.company_number == item.organization_set[0].company_number)).id;
                        item.org_id = item.organization_set[0].id;
                        item.org_name = item.organization_set[0].name; 
                    }
                    else{
                        // user create
                        item.organization_set[0].id = (response.data).organization_set[0].id;
                        item.org_id = item.organization_set[0].id;
                        item.org_name = item.organization_set[0].name; 
                    }
                }else{
                    // no company
                    item.org_id = null;
                    item.org_name = ''; 
                }
            }
            let updated_by = this.ownerList.find(e => e.id == response.data.updated_by);
            item.updated_by = updated_by;
            editData.updated_by = updated_by;
            item.updated_on = response.data.updated_on;
            editData.updated_on = response.data.updated_on;
        })
        .finally(()=>{
            this.loading_edit = false;
        })
    },
    onEmitInlineEdit_cf(item, prop, value){
        this.loading_edit = true;
        item['custom_field'][prop] = value;
        let editData = JSON.parse(JSON.stringify(item));

        CustomFieldDataService.updateContactCustomFieldDetail(editData.id,editData)
        .catch((response)=>{
            this.showErrorDialog(response);
        })
        .finally(()=>{
            let updated_by = this.ownerList.find(e => e.id == this.user.userid);
            item.updated_by = updated_by;
            item.updated_by = updated_by;
            item.updated_on = new Date();
            item.updated_on = new Date();
            this.loading_edit = false;
        })
    },
    getCampaginList(){
        CampaignDataService.selectlist()
        .then((response)=>{
            this.campaignList = response.data;
        })
    },
    getOwnerList(){
        OrganizationDataService.getchangeowner("contacts")
        .then((response)=>{
            this.ownerList = response.data;
            this.ownerList.forEach(item => {
                item.fullName = item.last_name + ' ' + item.first_name
            });
        });
    },
    async getCFdef(){
        let treeSelectData = [];
        await TreeOptionsDataService.list().then((response)=>{
            treeSelectData = response.data;
        })
        await CustomFieldDataService.getCustomFieldDetail("Contact")
        .then((response) => {
            this.CFdef =  response.data[0].definition;
            for(let prop in this.CFdef){
                if(prop.includes('tst')){
                    let treeData = treeSelectData.filter(el=>el.id == this.CFdef[prop].tree_select_id)[0]
                    let optList = treeData.option_set;
                    optList = Object.entries(optList).map((arr) => (
                    {
                        key: arr[0],
                        name: arr[1],
                    }));
                    this.CFdef[prop]['optList'] = optList;
                    this.CFdef[prop]['treeList'] = treeData.value;
                }
                if(prop.includes('opt') || prop.includes('mlt')){
                    let optList = Object.entries(this.CFdef[prop].option_set)
                    .map((arr) => (
                    {
                        key: arr[0],
                        name: arr[1],
                    }));
                    this.CFdef[prop]['optList'] = optList;
                }
            }
        })
    },
    onEmitView(view, mode){
        this.currentTable = view;

        if(mode != undefined){
            if(mode=="edit"){
                this.editMode = 'EDIT';
                this.showEditTableDialog();
            }
            else if(mode=='copy'){
                this.editMode = 'COPY';
                this.showEditTableDialog();
            }
            else if(mode=='add'){
                const userOwnCV = this.customViews.find(e => e.owner != null);
                let item = {
                    fields: view.fields,
                    is_default: false,
                    name: view.name,
                    id : view.view_id
                }
                view.owner = userOwnCV.owner;
                
                userOwnCV.definition.push(item);
                this.getHeader();
            }
        }else{
            this.getHeader();
        }
    },
    onEmitFilter(filter, situation){
        
        if(situation == 'cancelEdit'){
            for(let prop in this.default_filter){
                let p = prop.toString();
                this.$set(this.default_filter, p, filter[prop]) 
            }
            return;
        }
        if(situation == 'applyFilter' && filter != undefined){
            filter.owner_id = filter.owner;
            this.default_filter = filter;
            return;
        }
        if(situation == 'addFilter' && filter != undefined){
            this.filter_list.push(filter);
            filter.owner_id = filter.owner;
            this.default_filter = filter;
            return;
        }
        if(situation == 'confirmEdit_exportDialog'){
            let oldData = this.filter_list.find(e => e.id == filter.id);
            for(let prop in oldData){
                let p = prop.toString();
                this.$set(oldData, p, filter[prop]) 
            }
            return;
        }
    },
    async getFilterList(){
        await CustomFilterDataService.list()
        .then((response)=>{
            this.filter_list = response.data
            this.filter_list = this.filter_list.filter(el => el.class_name == "Contact")
        })
        .finally(()=>{
            this.system_default_filter = this.filter_list.find(e => e.default);
        })
    },
    async getListData(){
        this.loading_content = true;
        this.contactData = [];
        await ContactDataService.getAll(this.page,this.searchContact,this.itemPerPage,this.sortByForSearch,this.default_filter.id)
        .then(response => {
            this.contactData = response.data.list;
            this.pageCount = response.data.total_page;
            this.pageDataCount = response.data.pageDataCount;
            this.currentData = (this.page)*this.itemPerPage >= this.pageDataCount ? this.pageDataCount:(this.page)*this.itemPerPage
            this.contactData.forEach(data => {
                data.total_amount_adj = '$ '+this.getFormatAmount(data.total_amount);
                data.full_name = data.last_name;
                if(data.first_name != null){
                    data.full_name = data.last_name +" "+ data.first_name;
                }
                if (data.organization_set.length != 0) {
                    data.org_name = data.organization_set[0].name;
                }
                if (data.owner.first_name == null) {
                    data.owner_name = data.owner.last_name;
                } 
                else {
                    data.owner_name = data.owner.last_name +" "+ data.owner.first_name;
                }
                data.owner.fullName = data.owner.first_name == null ? data.owner.last_name : data.owner.last_name +" "+ data.owner.first_name;

            });
            this.loading_content = false;
        });
    },
    async getCustomView(){
        this.tables = [];
        CustomViewDataService.getCustomView('Contact')
        .then(response => {
            this.customViews = response.data;
            this.customViews.forEach(e => {
                if(e.owner == null){
                    let item = {
                        id: e.id,
                        name: e.definition[0].name,
                        is_default: e.definition[0].is_default,
                        fields: e.definition[0].fields,
                        owner: e.owner,
                        view_id : e.definition[0].id,
                    }
                    
                    if(item.is_default)
                        this.currentTable = item;
                    this.tables.push(item)
                }
                else if(e.owner != null){
                    e.definition.forEach(table => {
                        let item = {
                            id: e.id,
                            name: table.name,
                            is_default: table.is_default,
                            fields: table.fields,
                            owner: e.owner,
                            view_id : table.id,
                        }

                        if(item.is_default)
                            this.currentTable = item;
                        this.tables.push(item)
                    })
                }
            });
            this.tables.reverse()
            this.getHeader();
        })
        .catch(response => {
            this.showErrorDialog(response);
        })
    },
    async getHeader(){
        // await (this.loading = true);
        await (this.loading_content = true);
        let customWidthData = await this.getWidth("Contact",this.currentTable);
        await CustomFieldDataService.getCustomFieldDetail("Contact").then((response)=>{
          this.customField = response.data[0].definition;
        })
        let cfKey = this.customField ? Object.keys(this.customField) : [];
        this.loading = true;
        let array = [];
        let select = {
            value: "data-table-select",
            align: "center",
            width: '100px',
        };
        let action = {
            text: i18n.t('GENERAL.ACTIVE') ,
            value: "action",
            align: "center",
            width: '150px',
            sortable: false,
        };

        this.currentTable.fields.forEach(e => {
            let item = {
                text: '',
                value: e.value,
                sequence: e.sequence_number,
                align: 'center',
                sortable: true
            };
            // if(this.currentTable.fields.length >= 8){
            //     item.width = '200px';
            // }

            if(e.is_customfield && this.CFdef != null){
                item.text = e.text;
                item.value = 'custom_field_' + e.value;
                item.prop = e.value;
                item.type = (e.value).substr(0,3)
                this.cf_header.push(item);
            }
            else{
                item.text = this.getItemNameFromList(contactsFieldList , e.value);
            }
            if(e.is_customfield && cfKey.indexOf(e.value)!=-1){
              array.push(item);
            }
            if(!e.is_customfield){
              array.push(item);
            }
        })
        array.sort(function(a, b){
            return a.sequence_number - b.sequence_number
        });
        array.unshift(select);
        array.push(action);
        this.contactHeader = array;
        this.headerData.id = this.currentTable.view_id;
        this.headerData.name = this.currentTable.name;
        this.headerData.value = array;
        this.headerData.page = "Contact";
        this.headerData.customWidth = customWidthData;
        await (this.loading = false);
        await (this.loading_content = false);

        const title = localStorage.getItem('title');    
        document.title = title+' : '+this.currentTable.name +' – UpDay';
    },
    getHeaderwithWidth(data){
        this.contactHeader = data;
    },
    changeTable(item){
        this.currentTable = item;
        this.getHeader();
    },
    // onResize: function () {
    //   let windowH = window.innerHeight - 200; //table高度
    //   let objH = 70;
    //   this.itemPerPage = parseInt(windowH/objH);
    // },
    onCall: function (cell) {
      return "tel:" + cell;
    },
    onMail: function (mail) {
      return "mailto:" + mail;
    },
    onGoogleMap: function (address) {
      return "https://www.google.com.tw/maps/place/" + address;
    },
    // Dialog Area ----------------------------------------- [Start]
    showAddContact(copyData) {
      if(copyData == null){
        this.addContactData = {
          organization_set: [],
          first_name: null,
          last_name: null,
          email: null,
          mobile_phone: null,
          office_phone: null,
          level: null,     
          facebook: null,
          linkedin: null,
          instagram_id: null,
          line_id: null,
          custom_field: null
        }
        this.addContactDialog = true;
      }
      else{
        this.copyDataId = "contact"
        this.addContactData = JSON.parse(JSON.stringify(copyData));
        this.addContactData.custom_field = JSON.parse(this.addContactData.custom_field);
        delete this.addContactData.id;
        delete this.addContactData.updated_by;
        delete this.addContactData.created_by;  
        if(this.addContactData.mobile_phone == null){
          this.addContactData.mobile_phone = "";
        }
        if(this.addContactData.email == null){
          this.addContactData.email = "";
        }
        this.addContactDialog = true;
      }    
    },
    getLoginUserEmail() {
      O365Service.getO365Accounts()
      .then(response => {
          this.userEmail = response.data[0]? response.data[0].o365_user_principal_name:null;
      });
      // var user = window.localStorage.getItem('user');
      // if (user != null && user != undefined) {
      //     let obj = JSON.parse(user);
      //     return obj.email;
      // } else {
      //     return '';
      // }
    },
    // getWebPushPreference() {
    //   PreferenceService.getWebPush()
    //   .then(response => {
    //       // // if WEB_PUSH is Y, initialize and ask for web push notification permission
    //       // if (response.data == "Y")
    //         this.initWebPush();
    //   });
    // },
    getLoginUserData() {
      var user = window.localStorage.getItem('user');
      if (user != null && user != undefined) {
          let obj = JSON.parse(user);          
          return obj.last_name + obj.first_name;
      } else {
          return '';
      }
    },
    openEmailDrawer(){      
      this.objectType = "EMAIL";
      this.contactListDetail = this.selectedRows;
      this.emailDrawer =true;
      this.sentEmail = true;
      this.ownerData = this.getLoginUserData();
      this.sentEmailList = [];
      for(let i in this.selectedRows)
      {
        this.sentEmailList.push(this.selectedRows[i].id);
      }
    },
    openSMSDrawer(){
      this.objectType = "SMS";
      this.contactListDetail = this.selectedRows;
      this.SMSDrawer =true;
      this.sentSMS = true;
      this.ownerData = this.getLoginUserData();
      this.sentEmailList = [];
      for(let i in this.selectedRows)
      {
        this.sentEmailList.push(this.selectedRows[i].id);
      }
    },
    showAddStaticSeg(){
      this.addSegment={
        name: '',
        type: "STATIC",
        description: '',
        contacts:[]
      }     
      this.addSegDialog = true;
      for(let i in this.selectedRows)
      {
        this.addSegment.contacts.push(this.selectedRows[i].id);
      }      
    },
    onEmitAddSeg(val){      
      if(val){        
        this.addSegDialog = false;
        // this.showSuccessDialog();
      }
      else{
        this.addSegDialog = false;
      }      
      this.selectedRows = [];
    },
    onEmitAddContactDialog(val) {
      this.addContactDialog = false;
      this.selectedRows = [];
      if (val) {
        if(this.copyDataId == null)
        {this.showSuccessDialog();}
        else
        {
          this.redirectDialog = true
          this.message = ""
          this.copyDataId = this.addContactData.id
        }
        this.getListData();
      }else{
        this.copyDataId = null
      }
    },
    showEditContact(data) {
      this.originData = JSON.parse(JSON.stringify(data));
      this.editContactDialog = true;
      this.editContactData = data;
    },
    onEmitEditContactDialog(val) {
        this.editContactDialog = false;
        if (val) {
            this.showSuccessDialog();
            this.getListData();
        } 
        else{
            let index = this.contactData.findIndex((item) => item.id == this.originData.id);
            this.contactData.splice(index, 1, this.originData);
            
            if(this.contactData[index].first_name != null){
                this.contactData[index].full_name = this.contactData[index].last_name +" "+ this.contactData[index].first_name;
            }	
            else{
                this.contactData[index].full_name = this.contactData[index].last_name;
            }
            
            if (this.contactData[index].organization_set.length != 0){
                this.contactData[index].org_name = this.contactData[index].organization_set[0].name;
            }
        }
    },
    // Message Dialog
    async onDelete(id) {
      this.deleteContactIds = "";
      if(id != null){
        this.showMessageDialog(i18n.t('CONTACT.MESSAGE_DELETE'));
        this.deleteContactIds += id;
      }
      else{
        this.showMessageDialog(i18n.t('CONTACT.MESSAGE_DELETE'));
        for(let i = 0; i < this.selectedRows.length; i++)
        {
          this.deleteContactIds += `${this.selectedRows[i].id},`;
        }
        this.deleteContactIds = this.deleteContactIds.slice(0,-1);
      }
    },
    async onCopy(copyData) {
      let copyAddData = JSON.parse(JSON.stringify(copyData));
      copyAddData.custom_field = JSON.stringify(copyAddData.custom_field)
      this.showAddContact(copyAddData);
    },
    onEmitSendEmailDialog(val) {
        if (val) {
            this.showSuccessDialog();
        }
    },
    showSuccessDialog() {
      this.successDialog = true;
    },
    onEmitSuccessDialog() {
      this.successDialog = false;
    },
    showErrorDialog(message) {
      this.errorDialog = true;
      this.errorMessage = message;
    },
    onEmitErrorDialog() {
      this.errorDialog = false;
    },
    showMessageDialog(message) {
      this.messageDialog = true;
      this.message = message;
    },
    onEmitMessageDialog(val) {
      this.messageDialog = false;
      if (val) {
        if(this.deleteContactIds){
          ContactDataService.delete(this.deleteContactIds)
          .then((response) => {
            if(response.data.error === 'Have Opportunity!'){              
                  this.delError = true;  
                  let checkOpptylist = response.data.errorList.length
                  let checkCaseylist = response.data.caselist.length                  
                  if(checkOpptylist > 0 && checkCaseylist ==0){
                    this.showErrorDialog(`${i18n.t('CONTACT.MESSAGE_FIRST')} ${response.data.errorList} ${i18n.t('CONTACT.MESSAGE_OPPTY')}`);
                  }
                  else if(checkOpptylist == 0 && checkCaseylist > 0){
                    this.showErrorDialog(`${i18n.t('CONTACT.MESSAGE_FIRST')} ${response.data.caselist} ${i18n.t('CONTACT.MESSAGE_CASE')}`);
                  }
                  else {
                    this.showErrorDialog(`${i18n.t('CONTACT.MESSAGE_FIRST')} ${response.data.errorList} ${i18n.t('CONTACT.MESSAGE_OPPTY_2')}，${response.data.caselist} ${i18n.t('CONTACT.MESSAGE_CASE_2')}`);
                  }                  
              }
            else if(response.data.error === 'Have Opportunity or Case!'){
                  this.showErrorDialog(i18n.t('CONTACT.MESSAGE_ALL'));                  
              }
            else if (response.status === 204 || response.status === 200) {
              let IdArr = this.deleteContactIds.split(',');
              for(let i = 0; i < IdArr.length; i++){
                let deleteItemMethod = (item) => item.id === IdArr[i];
                let deleteItemIdx = this.contactData.findIndex(deleteItemMethod);
                this.contactData.splice(deleteItemIdx, 1);
              }
              if(this.selectedRows.length >0)
                {
                  this.currentData = this.currentData - this.selectedRows.length
                  this.pageDataCount = this.pageDataCount - this.selectedRows.length
              }else
              {
                this.currentData = this.currentData - 1
                this.pageDataCount = this.pageDataCount - 1
              }
              this.showSuccessDialog();
            } else {
              this.showMessageDialog(
                `${response.statusText}, ${response.status}`
              );
            }
            
            this.selectedRows = [];
            this.deleteContactIds ='';
          })
          .catch(() => {
            // handle error
            // console.log("Error is: " + error);
            this.showErrorDialog(i18n.t('CONTACT.MESSAGE_ERROR'));
          });
        }
      }else{
          if(this.deleteContactIds){
            this.deleteContactIds ='';
          }
      }
    },
    onEmitAddStaticSeg(val){
      if(val){
        this.addStaticSegDialog = false;
      }
      else{
        this.addStaticSegDialog = false;
      }
    },
    showEditTableDialog(){
        this.editTableDialog = true;
    },
    onEmitEditTableDialog(val){
        if(val){
            this.showSuccessDialog();
        }
        this.editTableDialog = false;
    },
    onEmitEditTable(data){
        if(this.editMode == 'EDIT'){
            this.currentTable.name = data.name;
            this.currentTable.fields = data.fields;
        }
        else if(this.editMode == 'COPY'){
            this.tables.unshift(data);
            this.currentTable = data;
        }
        this.getHeader();
    },
    // Dialog Area ----------------------------------------- [End]
    customFilter(value, search){
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().indexOf(search) !== -1
    },
    onEmitRedirectDialog(){
      this.redirectDialog = false
      this.copyDataId = null 
    },
    // initWebPush() {
    //   // console.log(this.$messaging);
    //   const messaging = getMessaging();
    //   //this.$messaging.onMessage(function (payload) {
    //   onMessage(messaging, (payload) => {
    //       console.log('Message received. ', payload);
    //       // let notification = payload.notification;
    //       let notificationData = payload.data;
    //       const notificationOptions = {
    //           body: notificationData.body,
    //           icon: notificationData.image,
    //           requireInteraction: true,
    //           data: { 
    //               url: notificationData.url
    //           }
    //       };
    //       // const notificationOptions = {
    //       //     body: notificationData.body,
    //       //     icon: notificationData.image,
    //       //     requireInteraction: true,
    //       //     data: { 
    //       //         url: notificationData.url
    //       //     },
    //       //     actions: [{action: "get", title: "Get Now"}, {action: "done", title: "Done Now"}]
    //       // };
    //       navigator.serviceWorker.getRegistrations()
    //           .then((registration) => {
    //               registration[0].showNotification(notificationData.title, notificationOptions);
    //           });
    //   });

    //   //this.$messaging.getToken({ vapidKey: "BO_TiDP7-49OyifEI7gGzftPHtjsy7uHAOpOw4K57QvqVCu5kGXBPE7i9X66MqyhtGDDh4zr-wRX4dOI3lRJx0E" })
    //   getToken(messaging, { vapidKey: "BO_TiDP7-49OyifEI7gGzftPHtjsy7uHAOpOw4K57QvqVCu5kGXBPE7i9X66MqyhtGDDh4zr-wRX4dOI3lRJx0E" })
    //   .then((currentToken) => {
    //       if (currentToken) {
    //         console.log('client token', currentToken);
    //       } else {
    //         console.log('No registration token available. Request permission to generate one.');
    //       }
    //   }).catch((err) => {
    //       console.log('An error occurred while retrieving token. ', err);
    //   });

    //   Notification.requestPermission().then(() => {
    //     console.log('Notification permission granted.')
    //     //this.$messaging.getToken({ vapidKey: "BO_TiDP7-49OyifEI7gGzftPHtjsy7uHAOpOw4K57QvqVCu5kGXBPE7i9X66MqyhtGDDh4zr-wRX4dOI3lRJx0E" }).then((token) => {
    //     getToken(messaging, { vapidKey: "BO_TiDP7-49OyifEI7gGzftPHtjsy7uHAOpOw4K57QvqVCu5kGXBPE7i9X66MqyhtGDDh4zr-wRX4dOI3lRJx0E" }).then((token) => {          
    //       console.log('New token created: ', token)
    //       if (this.fcmToken == token) {
    //         console.log('Same token');
    //       } else {
    //         console.log('Save notification token');
    //         AccountDataService.saveNotificationToken(token);
    //         window.localStorage.setItem('fcm_token', token);
    //       }
    //     })
    //   }).catch((err) => {
    //     console.log('Unable to get permission to notify.', err)
    //   });
    // },
    showBulkEditDialog(){
        this.bulkEditDialog = true;
    },
    async onEmitBulkEditDialog(val,editData){
        this.bulkEditDialog = false;
        if(val){
            if(val == 'editing'){
                this.loading = true;
                await BulkEditDataService.editContact(editData);
                await (this.selectedRows = []);
                await this.getListData();
                await(this.loading = false);
            }
        }
    },
  },
  watch:{
    "default_filter":function () {
      if(this.first_run){
        this.page = 1;
        this.getListData()
      } 
    },
    "emailDrawer":function () {
      if(this.emailDrawer == false)
      {
        this.sentEmail = false
      }     
    },
    "SMSDrawer":function () {
      if(this.SMSDrawer == false)
      {
        this.sentSMS = false
      }      
    },
    "redirectDialog":function () {
      if(this.redirectDialog == false)
      {
        this.copyDataId = null
      }      
    },
    "addContactDialog":function () {
      if(this.addContactDialog == false && this.copyDataId=="contact")
      {
        this.copyDataId = null        
      }      
    },    
    "page":async function () {      
      await this.getListData()  
    },
    "searchContact":async function () {
       if (!this.awaitingSearch) {
          setTimeout(() => {
            this.page = 1
            this.getListData();
            this.awaitingSearch = false;
          }, 1000);
        }
        this.awaitingSearch = true;
    },   
    "sortByDesc":async function () {   
      this.sortByForSearch = JSON.parse(JSON.stringify(this.sortBy))
      for(let i in this.sortByDesc)
      {
        if(this.sortByDesc[i] == true)
        {
          this.sortByForSearch[i] = '-'+this.sortByForSearch[i]          
        }        
      }
    //   this.page = 1;
      await this.getListData();
    },
  }
};
</script>